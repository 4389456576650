<template>
  <a-card v-resize="hideOverflowTitle" :widget="true" class="sentiment-comparison no-body-padding" title="Sentiment">
    <template v-if="!isMobileScreen" #extra>
      <a-radio-group v-model:value="mode" button-style="solid" size="default">
        <a-radio-button v-for="opt in modeOptions" :disabled="noData" :key="opt.value" :value="noData ? null : opt.value" style="height: 34px">
          {{ opt.label }}
        </a-radio-button>
        <!-- <a-radio-button value="engagement" style="height: 34px">Engagement</a-radio-button>
        <a-radio-button value="mention" style="height: 34px">Mentioned</a-radio-button> -->
      </a-radio-group>
    </template>
    <template v-else #extra>
      <a-dropdown placement="bottomRight" :getPopupContainer="(trigger) => trigger.parentNode" :disabled="noData">
        <FeatherIcon class="more-menu" type="more-horizontal" size="16" />
        <template #overlay>
          <a-menu id="mode-menu-mobile" @click="handleModeClick">
            <a-menu-item-group title="Measurement:">
              <a-menu-item
                v-for="opt in modeOptions"
                :key="opt.value"
                :style="mode === opt.value ? selectedModeStyle : unSelectedModeStyle"
              >
                {{ opt.label }}
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-dropdown>
      <!-- <a-dropdown placement="bottomRight" :getPopupContainer="(trigger) => trigger.parentNode">
        <FeatherIcon class="more-menu" type="more-horizontal" size="24" />
        <a-menu id="sentiment-mode-menu-mobile" @click="handleModeClick">
          <a-menu-item-group title="Measurement:">
            <a-menu-item key="engagement"> Engagement </a-menu-item>
            <a-menu-item key="mention"> Mentioned </a-menu-item>
          </a-menu-item-group>
        </a-menu>
      </a-dropdown> -->
    </template>
    <div v-if="loading" class="loading-wrapper">
      <a-skeleton :loading="loading" active />
    </div>
    <div v-else-if="!loading && noData">
      <NoDataFound></NoDataFound>
    </div>
    <div v-else class="item-wrapper">
      <div v-for="(c, index) in sentimentData" :key="`${c.name}-${index}`">
        <div v-if="!c.noData" class="item-row">
          <span class="title"> {{ c.name }} </span>
          <div class="prog-bar">
            <span
              v-for="sentiment in c.data"
              :key="sentiment.name"
              class="prog-segment has-data"
              :class="sentiment.name"
              :style="{ width: sentiment.percent + '%' }"
              :title="`${sentiment.name}: ${sentiment.percent}% (${sentiment.value})`"
              @click="onSegmentClick(c.name, sentiment.name)"
            >
              <span class="prog-inner-element"> {{ sentiment.percent }}% </span>
            </span>
            <!-- <span v-if="c.noData" class="prog-segment no-data"> No data </span> -->
          </div>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { onMounted, ref, reactive, toRefs, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import NoDataFound from '@/components/Error/NoDataFound.vue';

export default {
  props: {
    filterResult: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    NoDataFound,
  },

  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const categoryNameMap = computed(() => store.getters['config/defaultCategoryVisibleMap']);
    const { filterResult } = toRefs(props);

    const mode = ref('engagement');
    let loading = ref(false);
    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mention' },
      // { label: 'view', value: 'View' },
    ];

    // [ { name: 'Cat 1', data: [ 10, 20, 30 ] } ]
    const sentimentData = ref([]);
    const sentimentDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });

    const noData = computed(() => {
      return sentimentData.value.every((item) => item.noData === true)
    });

    const convertPortionData = (key, obj) => {
      if (obj.negativePercent === 0 && obj.positivePercent === 0 && obj.neutralPercent === 0) {
        return {
          name: key,
          noData: true,
        };
      }
      return {
        name: key,
        noData: false,
        data: [
          { name: 'negative', value: helper.numeral(obj.negative), percent: obj.negativePercent },
          { name: 'neutral', value: helper.numeral(obj.neutral), percent: obj.neutralPercent },
          { name: 'positive', value: helper.numeral(obj.positive), percent: obj.positivePercent },
        ],
      };
    };

    const init = async () => {
      loading.value = true;
      if (filterResult.value && filterResult.value.filterData) {
        const { filterData, categoryList, level } = filterResult.value;
        let c = categoryList;
        if (c.length === 0) {
          c = categoryNameMap.value[level];
        }
        if (filterResult.value.valid) {
          const result = await api.getCategoryPieSentiment(filterData, { level });
          // console.log('result 4444', result);

          if (result.message && result.message.categoryPortionSentiment) {
            // { cat: { count, engagement, view }}
            // TO count[], engage[], view[]
            const countList = [];
            const engageList = [];
            const viewList = [];
            for (let categoryKey in result.message.categoryPortionSentiment) {
              const { count, engagement, view } = result.message.categoryPortionSentiment[categoryKey];
              countList.push(convertPortionData(categoryKey, count));
              engageList.push(convertPortionData(categoryKey, engagement));
              viewList.push(convertPortionData(categoryKey, view));
            }
            sentimentDataByMode.engagement = engageList;
            sentimentDataByMode.mention = countList;
            sentimentDataByMode.view = viewList;

            sentimentData.value = sentimentDataByMode[mode.value].sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            });
            await nextTick();
            hideOverflowTitle();
          }

          // const result = await api
          //   .getCategorySentimentHistoryGraph(filterData, {
          //     level,
          //   })
          //   .catch(() => {
          //     console.error('Sentiment api call error', result);
          //   });
          // let finalResult = [];
          // if (result.message && result.message.graphData) {
          //   for (let categoryKey of c) {
          //     const categoryObj = result.message.graphData[categoryKey];
          //     let totalCount = 0;
          //     let positivePercent = 0;
          //     let negativePercent = 0;
          //     let neutralPercent = 0;
          //     let noData = false;
          //     if (categoryObj) {
          //       // Do count positive, neutral, negative
          //       let positiveCount = 0;
          //       let negativeCount = 0;
          //       for (let i of categoryObj.data) {
          //         if (i.y > 1) positiveCount += 1;
          //         else if (i.y < -1) negativeCount += 1;
          //       }

          //       totalCount = categoryObj.data.length;
          //       positivePercent = (positiveCount / totalCount) * 100;
          //       negativePercent = (negativeCount / totalCount) * 100;
          //       neutralPercent = 100 - (positivePercent + negativePercent);
          //     } else {
          //       noData = true;
          //     }

          //     finalResult.push({
          //       name: categoryKey,
          //       noData,
          //       data: [
          //         { name: 'negative', value: negativePercent, valueText: negativePercent.toFixed(2) },
          //         { name: 'neutral', value: neutralPercent, valueText: neutralPercent.toFixed(2) },
          //         { name: 'positive', value: positivePercent, valueText: positivePercent.toFixed(2) },
          //       ],
          //     });
          //   }
          //   sentimentData.value = finalResult;
          // }
          // await nextTick();
          // hideOverflowTitle();
        }
        // else {
        //   let finalResult = [];
        //   for (let categoryKey of c) {
        //     finalResult.push({
        //       name: categoryKey,
        //       noData: true,
        //     });
        //   }
        //   sentimentData.value = finalResult;
        // }
      }
      loading.value = false;
    };

    const onSegmentClick = (categoryData, sentimentData) => {
      const { filterData, level } = filterResult.value;
      const newFilter = JSON.parse(JSON.stringify(filterData));
      const sentiment = [sentimentData];
      const category = [categoryData]; // selected
      let f = {
        payload: {
          title: `Data for "${categoryData}" category and "${sentimentData}" sentiment `,
        },
        criteria: {
          sentiment,
          category: newFilter.category,
          subCategory: newFilter.subCategory,
          time: filterData.time,
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (level > 0) {
        for (let i in f.criteria['subCategory']) {
          if (f.criteria['subCategory'][i].level == level) {
            f.criteria['subCategory'][i].category = category;
          }
        }
      } else {
        f.criteria['category'] = category;
      }

      // if (level === 0) {
      //   f.criteria.category = category;
      // } else {
      //   f.criteria.subCategory = [
      //     {
      //       level,
      //       category,
      //     },
      //   ];
      // }
      store.dispatch('message/showMessageModal', f);
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.prog-inner-element');
      // console.log('ALLSS', allSegment);
      if (allSegment) {
        allSegment.forEach((obj) => {
          // console.log(obj.offsetWidth, obj.parentElement.offsetWidth);
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };

    const handleModeClick = (e) => {
      mode.value = e.key;
    };

    watch(mode, () => {
      sentimentData.value = sentimentDataByMode[mode.value].sort((a, b) => {
        if (a.name === 'others') {
          return 1;
        } else if (b.name === 'others') {
          return -1;
        } else {
          return 0;
        }
      });
    });

    watch(filterResult.value, () => {
      init();
    });

    onMounted(() => {
      init();
    });

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    return {
      mode,
      sentimentData,
      onSegmentClick,
      hideOverflowTitle,
      loading,
      isMobileScreen,
      handleModeClick,
      modeOptions,
      selectedModeStyle,
      unSelectedModeStyle,
      noData,
    };
  },
};
</script>

<style lang="scss">
.sentiment-comparison {
  .loading-wrapper {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }
  .item-wrapper {
    height: 400px;
    overflow-y: auto;
  }
  .item-row {
    margin: 16px 16px 16px 32px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .title {
      color: #868eae;
      font-size: 12px;
      min-width: 120px;
      flex: 0 0 120px;
      text-transform: capitalize;
    }
    .prog-bar {
      flex: 1 1 auto;
      display: flex;
      .prog-segment {
        text-align: center;
        color: #fff;
        height: 18 px;
        line-height: 18px;
        .prog-inner-element {
          font-size: 12px;
        }
      }
      .has-data {
        cursor: pointer;
      }
      .no-data {
        background-color: #aaa;
        width: 100%;
      }
      .positive {
        background-color: #20c997;
      }
      .neutral {
        background-color: #febc3c;
      }
      .negative {
        background-color: #ff4d4f;
      }
    }
  }
  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }
  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }
  .more-menu {
    color: #9299b8;
  }
}
</style>
