<template>
  <div class="engagement-bar" :class="{ dark: dark, 'mobile-bar': isMobile }">
    <div class="left-block" :class="{ 'engagement-block': isMobile }">
      <div class="total-engage">
        <!-- <span class="total-engage-title">Total: </span> -->
        <span class="total-engage-value">
          {{ engageValue.all }}
        </span>
        <span class="engagement-title">engagement</span>
      </div>
      <a-tooltip v-if="data.info.source === 'facebook'" placement="bottom">
        <template #title> Reaction: {{ engageValue.reactionFull }} </template>
        <div class="engage-value">
          <EngagementIcon :type="'reaction_count'" />
          <span>{{ engageValue.reaction }}</span>
        </div>
      </a-tooltip>
      <template v-else>
        <a-tooltip placement="bottom">
          <template #title> Like: {{ numeral(data.summary.like_count, '0,0', true) }} </template>
          <div v-if="data && data.summary && data.summary.like_count" class="engage-value icon">
            <FeatherIcon
              v-if="data.info.source === 'twitter' || data.info.source === 'twitterv2'"
              type="heart"
              size="14"
              class="engage-icon"
            />
            <FeatherIcon v-else type="thumbs-up" size="14" class="engage-icon" />
            <span>{{ numeral(data.summary.like_count, '0,0.0a', true) }}</span>
          </div>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title> Dislike: {{ numeral(data.summary.dislike_count, '0,0', true) }} </template>
          <div v-if="data && data.summary && data.summary.dislike_count" class="engage-value icon">
            <FeatherIcon type="thumbs-down" size="14" class="engage-icon" />
            <span>{{ numeral(data.summary.dislike_count, '0,0.0a', true) }}</span>
          </div>
        </a-tooltip>
      </template>
      <a-tooltip placement="bottom">
        <template v-if="data.info.source === 'twitter' || data.info.source === 'twitterv2'" #title>
          Reply: {{ numeral(data.summary.comment_count, '0,0', true) }}
        </template>
        <template v-else #title> Comment: {{ numeral(data.summary.comment_count, '0,0', true) }} </template>
        <div v-if="data && data.summary && data.summary.comment_count" class="engage-value icon">
          <FeatherIcon type="message-square" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.comment_count, '0,0.0a', true) }}</span>
        </div>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template #title> Share: {{ numeral(data.summary.share_count, '0,0', true) }} </template>
        <div v-if="data && data.summary && data.summary.share_count" class="engage-value icon">
          <FeatherIcon type="share-2" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.share_count, '0,0.0a', true) }}</span>
        </div>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template #title> Retweet: {{ numeral(data.summary.retweet_count, '0,0', true) }} </template>
        <div
          v-if="
            (data.info.source === 'twitter' || data.info.source === 'twitterv2') &&
            data &&
            data.summary &&
            data.summary.retweet_count
          "
          class="engage-value icon"
        >
          <FeatherIcon type="repeat" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.retweet_count, '0,0.0a', true) || '0' }}</span>
        </div>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template #title> Quote: {{ numeral(data.summary.quote_count, '0,0', true) }} </template>
        <div
          v-if="
            (data.info.source === 'twitter' || data.info.source === 'twitterv2') &&
            data &&
            data.summary &&
            data.summary.quote_count
          "
          class="engage-value icon"
        >
          <FeatherIcon type="edit-3" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.quote_count, '0,0.0a', true) || '0' }}</span>
        </div>
      </a-tooltip>
    </div>
    <div class="right-block" :class="{ 'view-block': isMobile }">
      <a-tooltip placement="bottom">
        <template #title> Page view: {{ numeral(data.summary.view_count, '0,0', true) }} </template>
        <div
          v-if="
            data &&
            data.info &&
            data.info.source !== 'youtube' &&
            data.info.source !== 'facebook' &&
            data.info.source !== 'instagram' &&
            data.info.source !== 'twitter' &&
            data.info.source !== 'twitterv2' &&
            data.info.source !== 'tiktok' &&
            data.summary &&
            data.summary.view_count
          "
          class="engage-value icon"
        >
          <FeatherIcon type="bar-chart" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.view_count, '0,0.0a', true) }}</span>
        </div>
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template #title> Video view: {{ numeral(data.summary.view_count, '0,0', true) }} </template>
        <div
          v-if="
            data &&
            data.info &&
            data.info.has_video &&
            (data.info.source === 'youtube' ||
              data.info.source === 'facebook' ||
              data.info.source === 'instagram' ||
              data.info.source === 'twitter' ||
              data.info.source === 'twitterv2' ||
              data.info.source === 'tiktok') &&
            data.summary &&
            data.summary.view_count
          "
          class="engage-value icon"
        >
          <FeatherIcon type="eye" size="14" class="engage-icon" />
          <span>{{ numeral(data.summary.view_count, '0,0.0a', true) }}</span>
        </div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import helper from '@/services/helper';
import EngagementIcon from './EngagementIcon.vue';

export default {
  name: 'EngagementBar',
  components: {
    EngagementIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    dark: Boolean,
  },
  computed: {
    engageValue() {
      let all = 0;
      let reaction = 0;
      if (this.data && this.data.info) {
        if (this.data.info.source === 'facebook') {
          let allValue = 0;
          let reactionValue = 0;
          let sumCommentShare = 0;
          let keys = Object.keys(this.data.summary);
          for (let summaryKey of keys) {
            if (summaryKey.indexOf('count') > -1 && summaryKey.indexOf('count_update') < 0) {
              if (summaryKey !== 'view_count' && summaryKey !== 'reaction_count') {
                if (this.data.summary[summaryKey]) {
                  // TODO check number ?
                  allValue += this.data.summary[summaryKey];
                  if (summaryKey === 'comment_count' || summaryKey === 'share_count') {
                    sumCommentShare += this.data.summary[summaryKey];
                  } else {
                    reactionValue += this.data.summary[summaryKey];
                  }
                }
              }
            }
          }
          // If field is more than calculated total - use original field instead
          if (this.data.summary && this.data.summary.reaction_count) {
            if (this.data.summary.reaction_count > reactionValue) {
              reactionValue = this.data.summary.reaction_count;
              allValue = reactionValue + sumCommentShare;
            }
          }
          if (this.data.analytic.engagement_score > allValue) {
            // console.log('SCORE > ALL');
            all = this.data.analytic.engagement_score;
            let showReaction = this.data.analytic.engagement_score - sumCommentShare;
            if (showReaction < 0) showReaction = 0;
            reaction = showReaction;
          } else {
            all = allValue;
            reaction = reactionValue;
          }
        } else {
          all = this.data.analytic.engagement_score;
        }
      }
      return {
        all: helper.numeral(all),
        reaction: helper.numeral(reaction, '0,0.0a', true, 0),
        reactionFull: helper.numeral(reaction, '0,0', true, 0),
      };
    },
  },
  methods: {
    numeral: helper.numeral,
  },
};
</script>

<style lang="scss" scoped>
.engagement-bar {
  font-size: 11px;
  color: #5a5f7d;
  //   font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .total-engage {
    background-color: #f8f9fb;
    white-space: nowrap;
    padding: 6px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 11px;
    .total-engage-title {
      margin-right: 3px;
    }
    .total-engage-value {
      color: #3371ff;
    }
    .engagement-title {
      margin-left: 5px;
      font-weight: 400;
      font-size: 13px;
      color: #9299b8;
    }
  }
  .engage-value {
    color: #9299b8;
    margin-right: 10px;
    margin-bottom: 11px;
    &.icon {
      display: flex;
      align-items: center;
    }
    .engage-icon {
      margin-right: 6px;
    }
  }
  .counter-wrapper-inner {
    display: flex;
    width: 100%;
  }
  .left-block,
  .right-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &.dark {
    .total-engage {
      background-color: #ffffff;
    }
  }
}
</style>
